/**
 * @generated SignedSource<<63db2964e02996244cfcfd10941d6371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePersonContactInput = {
  dataOrigin: DataOriginInput;
  emailAddress?: string | null;
  familyName: string;
  givenName: string;
  phoneNumber?: string | null;
  salutation: string;
};
export type DataOriginInput = {
  collectedAt: any;
  description: string;
  note?: string | null;
};
export type CreatePersonContactDialogMutation$variables = {
  input: CreatePersonContactInput;
};
export type CreatePersonContactDialogMutation$data = {
  readonly createPersonContact: {
    readonly emailAddress: string | null;
    readonly familyName: string;
    readonly givenName: string;
    readonly id: string;
    readonly phoneNumber: string | null;
    readonly salutation: string;
  };
};
export type CreatePersonContactDialogMutation = {
  response: CreatePersonContactDialogMutation$data;
  variables: CreatePersonContactDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PersonContact",
    "kind": "LinkedField",
    "name": "createPersonContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "givenName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "familyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePersonContactDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePersonContactDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "022cbde9ced47feb40470822c365b729",
    "id": null,
    "metadata": {},
    "name": "CreatePersonContactDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePersonContactDialogMutation(\n  $input: CreatePersonContactInput!\n) {\n  createPersonContact(input: $input) {\n    id\n    salutation\n    givenName\n    familyName\n    emailAddress\n    phoneNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e1255a842156034544f2a08700a14e0";

export default node;
