import * as React from "react";
import {graphql} from "react-relay";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {useFragment} from "react-relay/hooks";
import { FormattedDate } from "react-intl";
import ActionButton from "./presentational/button/ActionButton";

type Props = {
    queryRef: any
}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});


type ContactDataOriginListProps = Props & ConnectedProps<typeof connector>;

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const ContactDataOriginList: React.FC<ContactDataOriginListProps> = (props) => {
    const contact = useFragment(
        graphql`
            fragment ContactDataOriginList_contact on Contact {
                __typename
                id
                ... on PersonContact {
                    dataOrigins {

                        description
                        collectedAt
                        note
                    }
                }
            }
        `
        , props.queryRef
    )

    const {dispatchShowModal, dispatchHideModal} = props;

    return (
        <>
            <ul role="list" className="space-y-6">
                {contact.dataOrigins.map((dataOriginItem, dataOriginItemIdx) => (
                    <li key={dataOriginItem.id} className="flex gap-x-4">
                        <p className="grow py-0.5 text-gray-500">
                            <span className="font-medium text-gray-900">{dataOriginItem.description}</span>
                            {dataOriginItem.note && <span className="text-gray-500"><br/>({dataOriginItem.note})</span>}
                        </p>
                        <span className="shrink-0 py-0.5  text-gray-500">
                            <FormattedDate value={dataOriginItem.collectedAt} day="2-digit" month="2-digit" year="numeric"/>
                        </span>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default connector(ContactDataOriginList);
