import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";
import type {CreatePersonContactDialogMutation} from "../../__relay_artifacts__/CreatePersonContactDialogMutation.graphql";
import DatePickerField from "../form/DatePickerField";
import ComboBoxField from "../form/ComboBoxField";
import {useIntl} from "react-intl";

type CreatePersonContactDialogProps = {
    handleCloseDialog: () => {}
}

const CreatePersonContactDialog: React.FC<CreatePersonContactDialogProps> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();
    const intl = useIntl();

    const handleSubmission = (input) => {
        console.log('handleSubmission', input);
        commitMutation<CreatePersonContactDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreatePersonContactDialogMutation(
                        $input: CreatePersonContactInput!
                    ) {
                        createPersonContact(input: $input) {
                            id
                            salutation
                            givenName
                            familyName
                            emailAddress
                            phoneNumber
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    navigate('/contacts/' + response.createPersonContact?.id);
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }

    // Create a default date / time using the browser's timezone instead of UTC:
    const now = new Date();
    const pad = (num: number): string => {
        const numStr = num.toString();
        return numStr.length < 2 ? "0" + numStr : numStr;
    };
    const localDateTimeString = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())} ${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;

    const PREDEFINED_DESCRIPTIONS = [
        "phoneCall",
        "onlineMeeting",
        "email",
        "socialMedia",
        "formSubmission",
        "inPersonMeeting",
        "lookedUpInternet",
    ].map((id) => ({
        value: intl.formatMessage({id: `contact.dataOrigin.descriptions.${id}`}),
        label: intl.formatMessage({id: `contact.dataOrigin.descriptions.${id}`}),
    }));

    const initialValues = {
        salutation: "hello",
        dataOrigin: {
            collectedAt: localDateTimeString,
        },
    };

    const validationSchema = yup.object().shape({
        salutation: yup.string()
            .required(intl.formatMessage({id: 'validation.field.required'})),
        givenName: yup.string()
            .max(255, intl.formatMessage({id: 'validation.field.nameTooLong'}))
            .matches(/^[-+&\p{L}\p{P}\d .]{2,255}$/u, intl.formatMessage({id: 'validation.field.nameContainsInvalidCharacters'}))
            .required(intl.formatMessage({id: 'validation.field.required'})),
        familyName: yup.string()
            .max(255, intl.formatMessage({id: 'validation.field.nameTooLong'}))
            .matches(/^[-+&\p{L}\p{P}\d .]{2,255}$/u, intl.formatMessage({id: 'validation.field.nameContainsInvalidCharacters'}))
            .required(intl.formatMessage({id: 'validation.field.required'})),
        emailAddress: yup.string()
            .max(255, intl.formatMessage({id: 'validation.field.emailAddressTooLong'}))
            .email(intl.formatMessage({id: 'validation.field.emailAddressInvalid'}))
            .required(intl.formatMessage({id: 'validation.field.required'})),
        phoneNumber: yup.string() // The ITU E.164 states that phone numbers around the globe are recommended to not be longer than 15 digits, but we allow more digits to be safe
            .max(20, intl.formatMessage({id: 'validation.field.phoneNumberTooLong'}))
            .matches(/^(\+(?:[0-9] ?){6,19}[0-9])?$/, intl.formatMessage({id: 'validation.field.phoneNumberInvalid'})),
        // dataOrigin: yup.object().shape({
        //     description: yup.string()
        //         .required('This field is required')
        //         .max(255, 'This description is too long'),
        //     collectedAt: yup.string()
        //         .matches(
        //             /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}(:[0-9]{2})?$/,
        //             'Invalid date'
        //         )
        //         .test(
        //             'not-in-future',
        //             'The date cannot be in the future',
        //             (value) => {
        //                 if (!value) return false;
        //                 const collectedDate = new Date(value.replace(' ', 'T'));
        //                 return collectedDate <= new Date();
        //             }
        //         )
        //         .required(),
        //     note: yup.string()
        //         .max(4096, 'This note is too long')
        // })
    });

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline={intl.formatMessage({id: 'dialog.CreatePersonContactDialog.headline'})} submitLabel={intl.formatMessage({id: 'button.create'})}>
            <Section>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.salutation"})}</Label>
                    <Field name="salutation" as="select">
                        <option value="mr">{intl.formatMessage({id: "contact.salutation.mr"})}</option>
                        <option value="mrs">{intl.formatMessage({id: "contact.salutation.mrs"})}</option>
                        <option value="mx">{intl.formatMessage({id: "contact.salutation.mx"})}</option>
                        <option value="hello">{intl.formatMessage({id: "contact.salutation.hello"})}</option>
                    </Field>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.givenName"})}</Label>
                    <Field name="givenName" type="text" placeholder="John" autoFocus={true}/>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.familyName"})}</Label>
                    <Field name="familyName" type="text" placeholder="Doe"/>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.emailAddress"})}</Label>
                    <Field name="emailAddress" type="text" placeholder="john.doe@example.com"/>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.phoneNumber"})}</Label>
                    <Field name="phoneNumber" type="text" placeholder="+49 451 3050350"/>
                </Row>
                <Row>
                    <Label htmlFor="dataOrigin.description">{intl.formatMessage({id: "contact.dataOrigin.description.question"})}</Label>
                    <ComboBoxField name="dataOrigin.description" options={PREDEFINED_DESCRIPTIONS} placeholder={intl.formatMessage({id: "contact.dataOrigin.descriptions.selectDescription"})} customOptionLabel={intl.formatMessage({id: "contact.dataOrigin.descriptions.customDescription"})}/>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.dataOrigin.collectedAt.question"})}</Label>
                    <DatePickerField name="dataOrigin.collectedAt"/>
                </Row>
                <Row>
                    <Label>{intl.formatMessage({id: "contact.dataOrigin.note"})}</Label>
                    <Field name="dataOrigin.note" as="textarea" rows={4} cols={50} placeholder={intl.formatMessage({id: "contact.dataOrigin.note.placeholder"})}/>
                </Row>
            </Section>
        </FormDialog>
    )
}
export default CreatePersonContactDialog;
