import * as React from "react";
import {graphql} from "react-relay";
import Panel from "./presentational/Panel";
import {useFragment} from "react-relay/hooks";
import ContactDataOriginList from "./ContactDataOriginList";
import {hideModal, showModal} from "../store/actions";
import {connect, ConnectedProps} from "react-redux";
import {useOutletContext} from "react-router";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ContactDataProtectionViewProps = Props & ConnectedProps<typeof connector>;

const ContactDataProtectionView: React.FC<ContactDataProtectionViewProps> = (props) => {
    const queryRef: any = useOutletContext();

    const contact = useFragment(
        graphql`
            fragment ContactDataProtectionView_contact on Contact {
                __typename
                id
                ... on PersonContact {
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
                ...ContactDataOriginList_contact
            }
        `,
        queryRef
    );
    if (contact == null) {
        return <p>Contact not found</p>;
    }

    return (
        <>
            {contact.__typename === 'PersonContact' && (<Panel headline="Data Origins">
                <ContactDataOriginList queryRef={contact}/>
            </Panel>)}
        </>
    );
}

export default connector(ContactDataProtectionView);
