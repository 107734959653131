import * as React from "react";
import {graphql} from "react-relay";
import {Outlet} from "react-router";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import ProjectViewQuery, {ProjectViewQuery as ProjectViewQueryType} from "../__relay_artifacts__/ProjectViewQuery.graphql";
import SecondaryNavigation from "./navigation/SecondaryNavigation";
import ProjectHeader from "./ProjectHeader";

type ProjectViewParams = {
    prepared: any
};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ProjectViewProps = ProjectViewParams & ConnectedProps<typeof connector>;

const ProjectView: React.FC<ProjectViewProps> = (props) => {

    const {project} = usePreloadedQuery<ProjectViewQueryType>(
        graphql`
            query ProjectViewQuery($id: ID!) {
                project(id: $id) {
                    id
                    projectName
                    projectNumber
                    ...ProjectHeader_project
                    ...ProjectDetailsView_project
                }
            }
        `,
        props.prepared.projectViewQuery,
    );
    if (project == null) {
        return <p>Project not found</p>;
    }

    const breadcrumbNavigationItems = [
        {label: 'Projects', to: '/projects'},
        {label: project.projectName, to: '/projects/' + project.id + '/details'},
    ]

    const secondaryNavigationItems = [
        {
            label: 'Project',
            to: '/projects/' + project.id + '/details'
        },
        {
            label: 'Payment',
            to: '/projects/' + project.id + '/payment'
        },
    ]

    return (
        <>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <ProjectHeader project={project}/>
            <SecondaryNavigation items={secondaryNavigationItems}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Outlet context={project}/>
            </div>
        </>
    );
}

export default connector(ProjectView);

function prepare_ProjectView(params, relayEnvironment) {
    return {
        projectViewQuery: loadQuery(
            relayEnvironment,
            ProjectViewQuery,
            {
                id: params.id,
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_ProjectView};
