import * as React from "react";
import {graphql} from "react-relay";
import Panel from "./presentational/Panel";
import ContactDataList from "./ContactDataList";
import {useFragment} from "react-relay/hooks";
import {hideModal, showModal} from "../store/actions";
import {connect, ConnectedProps} from "react-redux";
import {useOutletContext} from "react-router";
import { useIntl } from "react-intl";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ContactContactViewProps = Props & ConnectedProps<typeof connector>;

const ContactContactView: React.FC<ContactContactViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const intl = useIntl();

    const contact = useFragment(
        graphql`
            fragment ContactContactView_contact on Contact {
                __typename
                id
                ...ContactDataList_contact
            }
        `,
        queryRef
    );
    if (contact == null) {
        return <p>{intl.formatMessage({ id: 'contact.notFound' })}</p>;
    }

    return (
        <>
            <Panel headline={contact.__typename === 'TeamContact' ? intl.formatMessage({id: 'contact.type.team'}) : intl.formatMessage({ id: 'contact.type.individual' })}>
                <ContactDataList queryRef={contact}/>
            </Panel>
        </>
    );
}

export default connector(ContactContactView);
