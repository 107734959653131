import * as React from "react";
import type {CabanaConfiguration} from "./configuration/CabanaConfiguration";
import {Route, Routes} from "react-router-dom";
import TransitioningContainer from "./components/TransitioningContainer";
import App from "./App";
import CustomersView, {prepare_CustomersView} from "./components/CustomersView";
import CustomerView, {prepare_CustomerView} from "./components/CustomerView";
import CustomerAccountView from "./components/CustomerAccountView";
import CustomerContactsView from "./components/CustomerContactsView";
import CustomerPaymentView from "./components/CustomerPaymentView";
import ContactsView, {prepare_ContactsView} from "./components/ContactsView";
import ContactView, {prepare_ContactView} from "./components/ContactView";
import InvoicesView, {prepare_InvoicesView} from "./components/InvoicesView";
import ConfigurationView, {prepare_ConfigurationView} from "./components/ConfigurationView";
import ConfigurationContractsView from "./components/ConfigurationContractsView";
import SubscriptionsView, {prepare_SubscriptionsView} from "./components/SubscriptionsView";
import SettlementsView, {prepare_SettlementsView} from "./components/SettlementsView";
import SettlementView, {prepare_SettlementView} from "./components/SettlementView";
import SubscriptionView, {prepare_SubscriptionView} from "./components/SubscriptionView";
import DraftInvoiceView from "./components/DraftInvoiceView";
import InvoiceView, {prepare_InvoiceView} from "./components/InvoiceView";
import FinalInvoiceView from "./components/FinalInvoiceView";
import {pdfjs} from 'react-pdf';
import ProjectsView, {prepare_ProjectsView} from "./components/ProjectsView";
import ProjectView, {prepare_ProjectView} from "./components/ProjectView";
import ProjectDetailsView from "./components/ProjectDetailsView";
import ProjectPaymentView from "./components/ProjectPaymentView";

import 'react-datepicker/dist/react-datepicker.css';
import ContactContactView from "./components/ContactContactView";
import ContactDataProtectionView from "./components/ContactDataProtectionView";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type RootProps = {
    configuration: CabanaConfiguration
}

const Root: React.FC<RootProps> = ({configuration}) => {
    return (
        <Routes>
            <Route path="/" element={<App/>}>
                <Route index element={<TransitioningContainer component={CustomersView} prepare={prepare_CustomersView}/>}/>

                <Route path="customers" element={<TransitioningContainer component={CustomersView} prepare={prepare_CustomersView}/>}/>
                <Route path="customers/:id" element={<TransitioningContainer component={CustomerView} prepare={prepare_CustomerView}/>}>
                    <Route index element={<CustomerAccountView/>}/>
                    <Route path="account" element={<CustomerAccountView/>}/>
                    <Route path="contacts" element={<CustomerContactsView/>}/>
                    <Route path="payment" element={<CustomerPaymentView/>}/>
                </Route>

                <Route path="contacts" element={<TransitioningContainer component={ContactsView} prepare={prepare_ContactsView}/>}/>
                <Route path="contacts/:id" element={<TransitioningContainer component={ContactView} prepare={prepare_ContactView}/>}>
                    <Route index element={<ContactContactView/>}/>
                    <Route path="details" element={<ContactContactView/>}/>
                    <Route path="data-protection" element={<ContactDataProtectionView/>}/>
                </Route>

                <Route path="projects" element={<TransitioningContainer component={ProjectsView} prepare={prepare_ProjectsView}/>}/>
                <Route path="projects/:id" element={<TransitioningContainer component={ProjectView} prepare={prepare_ProjectView}/>}>
                    <Route index element={<ProjectDetailsView/>}/>
                    <Route path="details" element={<ProjectDetailsView/>}/>
                    <Route path="payment" element={<ProjectPaymentView/>}/>
                </Route>

                <Route path="subscriptions" element={<TransitioningContainer component={SubscriptionsView} prepare={prepare_SubscriptionsView}/>}/>
                <Route path="subscriptions/:id" element={<TransitioningContainer component={SubscriptionView} prepare={prepare_SubscriptionView}/>}>
                    <Route index element={<SubscriptionView prepared={prepare_SubscriptionView}/>}/>
                </Route>

                <Route path="settlements" element={<TransitioningContainer component={SettlementsView} prepare={prepare_SettlementsView}/>}/>
                <Route path="settlements/:id" element={<TransitioningContainer component={SettlementView} prepare={prepare_SettlementView}/>}>
                    <Route index element={<SettlementView prepared={prepare_SettlementView}/>}/>
                    <Route path=":language" element={<SettlementView prepared={prepare_SettlementView}/>}/>
                </Route>

                <Route path="invoices" element={<TransitioningContainer component={InvoicesView} prepare={prepare_InvoicesView}/>}/>
                <Route path="invoices/:id" element={<TransitioningContainer component={InvoiceView} prepare={prepare_InvoiceView}/>}>
                    <Route index element={<FinalInvoiceView/>}/>
                    <Route path="edit" element={<DraftInvoiceView/>}/>
                </Route>

                <Route path="configuration" element={<TransitioningContainer component={ConfigurationView} prepare={prepare_ConfigurationView}/>}>
                    <Route index element={<ConfigurationContractsView/>}/>
                    <Route path="contracts" element={<ConfigurationContractsView/>}/>
                </Route>
            </Route>
        </Routes>
    );
}

export {CabanaConfiguration};
export default Root;
