import * as React from "react";
import {graphql} from "react-relay";
import ContactHeader from "./ContactHeader";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import SecondaryNavigation from "./navigation/SecondaryNavigation";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import type {ContactViewQuery as ContactViewQueryType} from "../__relay_artifacts__/ContactViewQuery.graphql";
import ContactViewQuery from "../__relay_artifacts__/ContactViewQuery.graphql";
import {hideModal, showModal} from "../store/actions";
import {connect} from "react-redux";
import {Outlet} from "react-router";

type ContactViewParams = {
    prepared: any
};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ContactViewProps = ContactViewParams;

const ContactView: React.FC<ContactViewProps> = (props) => {

    const {contact} = usePreloadedQuery<ContactViewQueryType>(
        graphql`
            query ContactViewQuery($id: ID!) {
                contact(id: $id) {
                    __typename
                    id
                    ... on PersonContact {
                        givenName
                        familyName
                    }
                    ... on TeamContact {
                        teamName
                    }
                    ...ContactContactView_contact
                    ...ContactDataProtectionView_contact
                }
            }
        `,
        props.prepared.contactViewQuery
    );
    if (contact == null) {
        return <p>Contact not found</p>;
    }

    const breadcrumbNavigationItems = [
        {label: 'Contacts', to: '/contacts'},
        {label: contact.teamName ?? (contact.givenName + ' ' + contact.familyName), to: '/contacts/' + contact.id},
    ]
    const secondaryNavigationItems = [
        {
            label: 'Contact',
            path: '/contacts/:id',
            to: '/contacts/' + contact.id + '/details',
            exact: true
        },
        {
            label: 'Data Protection',
            to: '/contacts/' + contact.id + '/data-protection',
            exact: true
        },
    ]

    return (
        <>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <ContactHeader contact={contact}/>
            <SecondaryNavigation items={secondaryNavigationItems}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Outlet context={contact}/>
            </div>
        </>
    );
}

export default connector(ContactView);

function prepare_ContactView(params, relayEnvironment) {
    return {
        contactViewQuery: loadQuery(
            relayEnvironment,
            ContactViewQuery,
            {
                id: params.id,
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_ContactView};
