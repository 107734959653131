import React from 'react';

const LanguageSelector: React.FC<{ locale: string, setLocale: (locale: string) => void }> =
    ({locale, setLocale}) => {
        return (
            <select
                value={locale}
                onChange={(e) => setLocale(e.target.value)}
                className="ml-4 border-none bg-gray-100 focus:border-none"
            >
                <option value="de-DE">🇩🇪</option>
                <option value="en-US">🇺🇸</option>
            </select>
        );
    };

export default LanguageSelector;
