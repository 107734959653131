import React, {useState} from 'react'
import Modal from "./components/dialog/Modal";
import Navigation from "./components/Navigation";
import ProfileDropdownPanel from "./components/ProfileDropdownPanel";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Outlet} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import Dinero from "dinero.js";
import {IntlProvider, useIntl} from "react-intl";
import enMessages from "./locales/en.json";
import deMessages from './locales/de.json';
import LanguageSelector from './components/navigation/LanguageSelector';

type AppProps = {}

const messages = {
    'en-US': enMessages,
    'de-DE': deMessages,
};

const NavigationContent: React.FC<{ sidebarOpen: boolean, setSidebarOpen: (open: boolean) => void, locale: string, setLocale: any}> =
    ({sidebarOpen, setSidebarOpen, locale, setLocale}) => {
        const intl = useIntl();

        const navigationItems = [
            {label: "navigation.customers", path: "/customers"},
            {label: "navigation.contacts", path: "/contacts"},
            {label: "navigation.projects", path: "/projects"},
            {label: "navigation.subscriptions", path: "/subscriptions"},
            {label: "navigation.settlements", path: "/settlements"},
            {label: "navigation.invoices", path: "/invoices"},
        ].map(item => ({
            ...item,
            label: intl.formatMessage({id: item.label})
        }));

        const secondaryNavigationItems = [
            {label: "navigation.configuration", path: "/configuration/contracts"},
        ].map(item => ({
            ...item,
            label: intl.formatMessage({id: item.label})
        }))

        return (
            <div className="relative h-screen flex overflow-hidden bg-gray-100">
                <Navigation
                    setSidebarOpen={setSidebarOpen}
                    sidebarOpen={sidebarOpen}
                    navigationItems={navigationItems}
                    secondaryNavigationItems={secondaryNavigationItems}
                />
                <div className="flex-1 overflow-auto focus:outline-none">
                    {/* could maybe become an AppHeader component */}
                    <div className="flex shrink-0 relative z-10 bg-gray-100 border-b border-gray-200  shadow-lg">
                        <button type="button" className="px-4 h-16 border-r border-gray-200 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <FontAwesomeIcon icon={faBars} className="w-4 h-4" aria-hidden="true"/>
                        </button>
                        <div className="flex-1 px-4 flex justify-between sm:px-6 max-w-5xl lg:mx-auto lg:px-8">
                            <div className="flex w-full md:ml-0">
                            </div>
                            {/*<UniversalSearch/>*/}
                            <LanguageSelector locale={locale} setLocale={setLocale}/>
                            <ProfileDropdownPanel/>
                        </div>
                    </div>
                    <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                        <Outlet/>
                    </main>
                </div>
            </div>
        );
    };

const App: React.FC<AppProps> = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [locale, setLocale] = useState('de-DE');

    Dinero.globalLocale = locale
    Dinero.defaultCurrency = 'EUR'

    return (
        <>
            <IntlProvider locale={locale} defaultLocale="en-US" messages={messages[locale]}>
                <NavigationContent sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} locale={locale} setLocale={setLocale}/>
                <Modal/>
            </IntlProvider>
        </>
    )
}

export default withAuthenticationRequired(App);
