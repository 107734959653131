import * as React from "react";
import { ErrorMessage, Field, useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBallotCheck } from "@fortawesome/pro-regular-svg-icons";

type Option = {
    value: string;
    label: string;
};

type ComboBoxFieldProps = {
    name: string;
    options: Option[];
    placeholder?: string;
    customOptionLabel?: string;
};

const ComboBoxField: React.FC<ComboBoxFieldProps> = ({ name, options, placeholder = "Select an option...", customOptionLabel = "Enter custom description...", ...props }) => {
    const [field, meta, helpers] = useField(name);
    const [isCustom, setIsCustom] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    // Check if the current value is one of the predefined options
    React.useEffect(() => {
        if (field.value && !options.some(option => option.value === field.value)) {
            setIsCustom(true);
        }
    }, [field.value, options]);

    React.useEffect(() => {
        if (isCustom && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isCustom]);

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "custom") {
            setIsCustom(true);
            helpers.setValue(""); // Clear the value when switching to custom
        } else {
            helpers.setValue(e.target.value);
        }
    };

    const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(e.target.value);
    };

    const handleCustomComplete = () => {
        setIsCustom(false);
    };

    return (
        <div className="flex flex-col space-y-2">
            {!isCustom ? (
                <Field
                    as="select"
                    name={name}
                    className="block w-full shadow-sm sm:text-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                    onChange={handleSelectChange}
                    {...props}
                >
                    <option value="">{placeholder}</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                    <option value="custom">{customOptionLabel}</option>
                </Field>
            ) : (
                <div className="flex space-x-2">
                    <Field
                        innerRef={inputRef}
                        type="text"
                        name={name}
                        className="block w-full shadow-sm sm:text-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                        onChange={handleCustomInputChange}
                        value={field.value}
                        {...props}
                    />
                    <button
                        type="button"
                        onClick={handleCustomComplete}
                        className="inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md"
                    >
                        <FontAwesomeIcon icon={faBallotCheck} className="h-5 w-5 mr-3" />
                    </button>
                </div>
            )}
            <ErrorMessage name={name} component="p" className="mt-2 text-sm text-red-600" />
        </div>
    );
};

export default ComboBoxField;
