import * as React from "react";
import DatePicker from "react-datepicker";
import {ErrorMessage, useField, useFormikContext} from "formik";

interface DatePickerFieldProps {
    name: string;
    dateFormat?: string;
    timeIntervals?: number;

    [key: string]: any;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({name, dateFormat = "yyyy-MM-dd HH:mm:ss", timeIntervals = 1, ...props}) => {
    const {setFieldValue} = useFormikContext<any>();
    const [field] = useField(name);

    return (
        <div>
            <DatePicker
                {...props}
                selected={field.value ? new Date(field.value) : null}
                onChange={(date: Date) => {
                    console.log(date ? date.toISOString().replace('T', ' ').slice(0, 19) : '');
                    setFieldValue(name, date ? date.toISOString().replace('T', ' ').slice(0, 19) : '');
                }}
                showIcon={true}
                timeIntervals={timeIntervals}
                dateFormat={dateFormat}
                closeOnScroll={true}
                className="block w-full shadow-sm sm:text-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
            />
            <ErrorMessage name={name} component="p" className="mt-2 text-sm text-red-600"/>
        </div>
    );
};

export default DatePickerField;
