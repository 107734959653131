/**
 * @generated SignedSource<<d70ce91e47cc34a884da457b63490fcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactDataProtectionView_contact$data = {
  readonly __typename: string;
  readonly familyName?: string;
  readonly givenName?: string;
  readonly id: string;
  readonly teamName?: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContactDataOriginList_contact">;
  readonly " $fragmentType": "ContactDataProtectionView_contact";
};
export type ContactDataProtectionView_contact$key = {
  readonly " $data"?: ContactDataProtectionView_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactDataProtectionView_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactDataProtectionView_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "givenName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        }
      ],
      "type": "PersonContact",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamName",
          "storageKey": null
        }
      ],
      "type": "TeamContact",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContactDataOriginList_contact"
    }
  ],
  "type": "Contact",
  "abstractKey": "__isContact"
};

(node as any).hash = "1d80817c39694bb6835911f85e30e94d";

export default node;
