/**
 * @generated SignedSource<<45b74268c69bc27e210664b3bafc09c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactDataOriginList_contact$data = {
  readonly __typename: string;
  readonly dataOrigins?: ReadonlyArray<{
    readonly collectedAt: any;
    readonly description: string;
    readonly note: string | null;
  } | null>;
  readonly id: string;
  readonly " $fragmentType": "ContactDataOriginList_contact";
};
export type ContactDataOriginList_contact$key = {
  readonly " $data"?: ContactDataOriginList_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactDataOriginList_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactDataOriginList_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DataOrigin",
          "kind": "LinkedField",
          "name": "dataOrigins",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collectedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PersonContact",
      "abstractKey": null
    }
  ],
  "type": "Contact",
  "abstractKey": "__isContact"
};

(node as any).hash = "6e4a8e93d8913adab0ecfa19758b036c";

export default node;
